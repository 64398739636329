import { useMatches } from "@remix-run/react";

export function useRouteData<T>(routeId: string): T | undefined {
  const matches = useMatches();
  const data = matches.find((match) => match.id === routeId)?.data;
  return data as T | undefined;
}

export function useRouteDataOrThrowError<T>(routeId: string): T {
  const data = useRouteData<T>(routeId);
  if (data === undefined) {
    throw new Error(`Route data not found for routeId: ${routeId}`);
  }
  return data as T;
}
